<template>
  <tabs-topbar
    :tabs="visibleTabs"
    data-id-prefix="absence_topbar_tabs"
    :modules="[]"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'
import { MODULE_NAME as ABSENCE_CALENDAR_MODULE_NAME } from '@/app/modules/calendar/calendar.module'
import { MODULE_NAME as SHIFT_PLAN_MODULE_NAME } from '@/app/modules/shifts-calendar/shifts-calendar.module'
import { USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE } from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'CalendarTopbarTabs',
  components: {
    TabsTopbar,
  },
  data() {
    return {
      tabs: [
        {
          id: SHIFT_PLAN_MODULE_NAME,
          label: () => this.$t('modules.shifts_calendar.label'),
          route: { name: `${SHIFT_PLAN_MODULE_NAME}.index` },
        },
        {
          id: ABSENCE_CALENDAR_MODULE_NAME,
          label: () => this.$t('modules.absence_calendar.label'),
          route: { name: `${ABSENCE_CALENDAR_MODULE_NAME}.index` },
        },
      ],
    }
  },
  computed: {
    visibleTabs() {
      return this.tabs.filter((module) =>
        this.$store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](module.id)
      )
    },
  },
}
</script>
