






























import { defineComponent, computed, ref } from '@vue/composition-api'
import store from '@/store'
import BaseCalendar from '@/app/util-modules/ui/calendar/BaseCalendar.vue'
import {
  EMPLOYEE_ACTION_LIST_EMPLOYEES,
  EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES,
} from '@/app/modules/employee/store/actions/employee.actions.names'
import {
  EMPLOYEE_MUTATION_SET_CURRENT_TAB,
  EMPLOYEE_MUTATION_RESET_FILTERS,
} from '@/app/modules/employee/store/mutations/employee.mutations.names'
import {
  ABSENCE_ACTION_APPROVE_ABSENCE,
  ABSENCE_ACTION_REJECT_ABSENCE,
  ABSENCE_ACTION_LIST_CALENDAR_ABSENCES,
} from '@/app/modules/absence/store/actions/absence.actions.names'
import { ABSENCE_STATUS } from '@/app/modules/absence/constants'
import { ABSENCE_MUTATION_SET_DATE_RANGE_CALENDAR } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '@/app/modules/employee/employee.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'
import {
  ABSENCE_TYPE,
  Absence,
} from '@/app/modules/absence/models/absence.model'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  setHours,
  setMinutes,
  isBefore,
} from 'date-fns'
import { formatDateFilter } from '@/utils/date-time.utils'

type AbsenceEvent = {
  id: string
  start?: Date
  end?: Date
  title: string
  allDay: boolean
  resource: string
  confirmationStatus: string
  canUpdate: boolean
}

type DateEvents = {
  [date: string]: {
    fullDay: AbsenceEvent[]
    partDay: AbsenceEvent[]
  }
}

export default defineComponent({
  name: 'AbsenceCalendar',
  components: {
    BaseCalendar,
    BaseLoading,
  },
  setup() {
    const PART_DAY_LIMIT = 2

    store.commit(EMPLOYEE_MUTATION_RESET_FILTERS)
    store.commit(EMPLOYEE_MUTATION_SET_CURRENT_TAB, { tab: 'active' })

    const currentDate = ref(new Date())
    const getFirstDayOfMonthBefore = (date = new Date()) => {
      return date.getMonth() === 0
        ? addMonths(date, 2)
        : startOfMonth(subMonths(date, 1))
    }
    const getLastDayOfMonthAfter = (date = new Date()) => {
      return date.getMonth() === 11
        ? subMonths(date, 2)
        : endOfMonth(addMonths(date, 1))
    }

    const $actions = useActionTracker({
      listEmployees: EMPLOYEE_ACTION_LIST_EMPLOYEES,
      listCalendarAbsences: ABSENCE_ACTION_LIST_CALENDAR_ABSENCES,
      appendEmployees: EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES,
    })
    const refetchEmployeesAction = () =>
      store.dispatch(EMPLOYEE_ACTION_LIST_EMPLOYEES)

    const fetchMoreEmployeesAction = () =>
      store.dispatch(EMPLOYEE_ACTION_LIST_APPEND_EMPLOYEES)
    const fetchMoreEmployees = () => {
      if (
        $actions.appendEmployees.isLoading ||
        $actions.listEmployees.isLoading
      )
        return
      fetchMoreEmployeesAction()
    }
    const fetchMoreAbsence = (date: Date) => {
      const firstDayOfMonthBefore = getFirstDayOfMonthBefore(date)
      const LastDayOfMonthAfter = getLastDayOfMonthAfter(date)

      const startDate = isBefore(firstDayOfMonthBefore, LastDayOfMonthAfter)
        ? firstDayOfMonthBefore
        : LastDayOfMonthAfter
      const endDate = isBefore(firstDayOfMonthBefore, LastDayOfMonthAfter)
        ? LastDayOfMonthAfter
        : firstDayOfMonthBefore

      store.commit(ABSENCE_MUTATION_SET_DATE_RANGE_CALENDAR, [
        startDate,
        endDate,
      ])
      store.dispatch(
        ABSENCE_ACTION_LIST_CALENDAR_ABSENCES,

        {
          start_date: formatDateFilter(new Date(startDate)),
          end_date: formatDateFilter(new Date(endDate)),
        }
      )
    }
    const employees = computed(() =>
      store.state[EMPLOYEE_MODULE_NAME].records.user.map(
        (employee: { id: number; username: string }) => ({
          id: employee.id,
          name: employee.username,
        })
      )
    )

    const absences = computed(() => {
      const absenceRecords =
        store.state[ABSENCE_MODULE_NAME].records[ABSENCE_TYPE]

      const groupedAbsences: any = {}
      absenceRecords.forEach((absence: any) => {
        const startDate = new Date(absence.starts_at).toLocaleDateString()

        if (!groupedAbsences[startDate]) {
          groupedAbsences[startDate] = {
            fullDay: [],
            partDay: [],
          }
        }

        if (absence.full_day) {
          groupedAbsences[startDate].fullDay.push({
            id: absence.id,
            start: new Date(absence.starts_at),
            end: new Date(absence.ends_at),
            title: absence.absence_type?.title,
            allDay: true,
            resource: absence.user.id,
            confirmationStatus: absence.confirmation_status,
            canUpdate:
              absence.permissions?.update &&
              absence.confirmation_status === ABSENCE_STATUS.PENDING,
          })
        } else {
          const partDayCount = groupedAbsences[startDate].partDay.length

          if (partDayCount < PART_DAY_LIMIT) {
            const partDayIndex = groupedAbsences[startDate].partDay.findIndex(
              (partDayAbsence: any) => partDayAbsence.id === absence.id
            )

            const partDayAbsence = {
              id: absence.id,
              originalStartDate: new Date(absence.starts_at),
              originalEndDate: new Date(absence.ends_at),
              title: absence.absence_type?.title,
              allDay: false,
              resource: absence.user.id,
              confirmationStatus: absence.confirmation_status,
              canUpdate:
                absence.permissions?.update &&
                absence.confirmation_status === ABSENCE_STATUS.PENDING,
            }

            if (partDayIndex !== -1) {
              groupedAbsences[startDate].partDay[partDayIndex] = partDayAbsence
            } else {
              groupedAbsences[startDate].partDay.push(partDayAbsence)
            }

            const sortedPartDayAbsences = groupedAbsences[
              startDate
            ].partDay.sort(
              (a: any, b: any) => a.originalStartDate - b.originalStartDate
            )

            sortedPartDayAbsences.forEach((partDayAbsence: any, index: any) => {
              const partDayStartDateTime =
                index === 0
                  ? setHours(setMinutes(partDayAbsence.originalStartDate, 0), 0)
                  : setHours(setMinutes(partDayAbsence.originalEndDate, 0), 12)

              const partDayEndDateTime =
                index === 0
                  ? setHours(
                      setMinutes(partDayAbsence.originalStartDate, 0),
                      12
                    )
                  : setHours(setMinutes(partDayAbsence.originalEndDate, 0), 24)

              partDayAbsence.start = partDayStartDateTime
              partDayAbsence.end = partDayEndDateTime

              groupedAbsences[startDate].partDay[index] = partDayAbsence
            })
          }
        }
      })

      const flattenedAbsences: DateEvents[] = []
      Object.keys(groupedAbsences).forEach((date) => {
        flattenedAbsences.push(
          ...groupedAbsences[date].fullDay,
          ...groupedAbsences[date].partDay
        )
      })

      return flattenedAbsences
    })

    refetchEmployeesAction()

    fetchMoreAbsence(currentDate.value)

    const handleApproveAction = async (absence: Absence) => {
      await store.dispatch(ABSENCE_ACTION_APPROVE_ABSENCE, {
        absenceId: absence.id,
      })
    }
    const handleRejectAction = async (absence: Absence) => {
      await store.dispatch(ABSENCE_ACTION_REJECT_ABSENCE, {
        absenceId: absence.id,
      })
    }

    return {
      employees,
      absences,
      $actions,
      fetchMoreEmployees,
      currentDate,
      fetchMoreAbsence,
      getFirstDayOfMonthBefore,
      getLastDayOfMonthAfter,
      handleApproveAction,
      handleRejectAction,
    }
  },
})
