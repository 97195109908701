import shiftsCalendarRoutes from '@/app/modules/shifts-calendar/routes/shifts-calendar.routes'
import { MODULE_NAME } from '../calendar.module'
import CalendarTopbarTabs from '../components/CalendarTopbarTabs.vue'

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout.vue'
  )

export default [
  {
    path: '/workspaces/:workspaceId/absence-calendar',
    name: `${MODULE_NAME}.index`,
    meta: {
      feature: MODULE_NAME,
      layout: DefaultLayout,
      hiddenElements: ['areas', 'tags'],
    },
    components: {
      'topbar-left': CalendarTopbarTabs,
      default: () => import('../pages/AbsenceCalendarPage.vue'),
    },
  },
  ...shiftsCalendarRoutes,
]
