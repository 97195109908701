






























import { defineComponent } from '@vue/composition-api'
import CalendarMobileContent from '../components/CalendarMobileContent.vue'
import AbsenceCalendar from '@/app/modules/calendar/components/AbsenceCalendar.vue'
import BaseTopBarNotification from '@/app/util-modules/ui/top-bar-notification/BaseTopBarNotification.vue'

export default defineComponent({
  name: 'AbsenceCalendarPage',
  components: {
    CalendarMobileContent,
    AbsenceCalendar,
    BaseTopBarNotification,
  },
})
