<template>
  <div class="mt-56 ml-px px-10 flex items-center justify-center">
    <div>
      <div class="flex">
        <base-icon
          icon="phone-mobile-cancel/phone-mobile-cancel-24"
          class="text-coal-300 mr-2 mb-px text-lg"
          size="1.5rem"
        />
        <h2 class="font-bold text-xl">
          {{ $t('calendar.mobile.absence_calendar') }}</h2
        >
      </div>
      <p class="mt-3.5">
        <span class="font-bold">
          {{ $t('sidebar.calendar') }}
        </span>
        {{ $t('calendar.mobile.not_available_text') }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default defineComponent({
  name: 'CalendarMobileContent',
  components: {
    BaseIcon,
  },
})
</script>
