import { State, RecordsCollection, RecordsIndexes } from '@/utils/jsonapi/types'

export interface CalendarState extends State {
  records: RecordsCollection
  recordsIndexes: RecordsIndexes
}

export const makeEmptyState = (): CalendarState => ({
  records: {},
  recordsIndexes: {},
})

export default makeEmptyState()
